import React, { useRef } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import './index.css';
import weBelieveOrange from '../../../img/we-believe-orange-icons-with-circle.svg';
import weBelieveRed from '../../../img/we-believe-red-icons-with-circle.svg';

export default () => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  return (
    <section className="uxui-we">
      <div ref={ref} className="top root-padding">
        <div className="left">
          <p className="c-title">We Believe</p>
          <AnimatedDots completeOnScreen={completeOnScreen} />
          <p className="body">
            <b>A quality user experience extends to all devices.</b> Thus our
            design process includes comprehensive discussion and planning for
            responsive situations in tandem with hi-fidelity responsive
            deliverables.
            <br />
            <br /> <b> A quality user experience is inclusive.</b> Our design
            team is well versed in digital regulations set for ADA disability.
            We strive to deliver an inclusive and delightful experience for all.
          </p>
        </div>
        <img
          src={weBelieveOrange}
          alt="We believe"
        />
      </div>
      <div className="bottom secondary-padding">
        <img
          src={weBelieveRed}
          alt="We believe"
        />
        <p>
          <b>Flexible content drives product success.</b> Our designs are
          hyper-focused on your user, but we also consider your internal teams
          who update content and data in the back end.
          <br />
          <br /> <b>Your product should be visible.</b> At Devetry, we include
          SEO best practices for marketing teams, advanced tools and
          customization for sales teams, and widgets for customer service teams.
        </p>
      </div>
    </section>
  );
};

import React from 'react';

import './index.css';
import workDots from '../../../img/work-dots.svg';
import hero from '../../../img/ux-ui-hero-2.png';

export default () => {
  return (
    <section className="uiux-hero">
      <img
        className="center-dots"
        src={workDots}
        alt="Dots"
      />
      <img
        className="left-dots"
        src={workDots}
        alt="Dots"
      />
      <img
        className="right-dots"
        src={workDots}
        alt="Dots"
      />
      <div className="top root-padding">
        <img
          className="right"
          src={hero}
          alt="Hero"
        />
        <div className="left">
          <p className="c-title">UX/UI Design</p>
          <p className="subtitle">
            Devetry helps you delight and engage your users through strategic,
            human-centric UX/UI design.
          </p>
        </div>
      </div>
      <div className="bottom secondary-padding">
        <p>
          Your UX/UI is how your audience interacts with your technology. It has
          the power to attract users, encourage conversions, and re-engage
          subscribers. <br />
          <br />
          We believe that understanding your user is essential to the success of
          any design effort. To understand your users is to empathize with them,
          see the product through their eyes, and recognize how their unique
          backgrounds and situations in life shape the relationship they have
          with your product.
          <br />
          <br /> The design team at Devetry creates stunning web apps, mobile
          apps, and other software interfaces for your users and their unique
          perspectives. Our human-centric approach means that we design for your
          users, collect their feedback, and iterate until we arrive at
          impactful designs.
        </p>
      </div>
    </section>
  );
};

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';
import './index.css';

// Sections
import Hero from '../../components/UXUISections/Hero';
import Research from '../../components/UXUISections/Research';
import RecentWork from '../../components/homeSections/RecentWork';
import Red from '../../components/UXUISections/Red';
import WeBelieve from '../../components/UXUISections/WeBelieve';
import Quote from '../../components/Quote';

const pageTitle = 'UX/UI Design Services | Software & Application Design | Devetry';
const pageDescription =
  "Our UX/UI design services include customer research, interviews, surveys, testing, and more. Learn more about Devetry's UX/UI designers and process.";

const UXUIPageTemplate = ({ designBlogs, recommended }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container ux-design">
        <Hero />
        <Research />
        <RecentWork title="Featured UI/UX Projects" items={recommended} />
        <Red
          title="At the end of the day, designs are useless if they can’t be built"
          body={
            <span>
              Cohesion between your design and development team is essential to
              building a great digital product. Our design process heavily
              incorporates our development team to ensure a realistic design
              strategy that can be implemented and understood by all parties
              involved. <br />
              <br /> Our design method makes a concentrated effort to understand
              the design systems, frameworks and content management systems,
              data, and unique functionality. This symbiotic relationship
              between design and development promotes transparency and
              discussion upfront which alleviates headaches and
              misunderstandings down the line.
            </span>
          }
        />
        <WeBelieve />
        <Quote
          blogs={designBlogs}
          title="Refine Your Design Skills"
          name="Laura Jean"
          position="Mesa Valley Community School"
          quote="I want to thank you for your amazing work on fixing the site as we
          have begun the year. You’ve been phenomenally responsive (and
          effective!). It has been so encouraging to have a way to clearly
          communicate bugs and their status and see prompt responses to the
          issues. Thank you for your continued support in keeping all the plates
          spinning!"
        />
      </div>
    </>
  );
};

const UXUIPage = ({ data }) => {
  const { fields } = data.markdownRemark;
  const { edges } = data.allMarkdownRemark;
  const designBlogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const recommended = [];
  for (const rec in fields) {
    if (Object.hasOwnProperty.call(fields, rec)) {
      const element = fields[rec];
      recommended.push({
        slug: element.fields.slug,
        ...element.frontmatter
      });
    }
  }
  return (
    <UXUIPageTemplate recommended={recommended} designBlogs={designBlogs} />
  );
};

UXUIPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default UXUIPage;

export const pageQuery = graphql`
  query UXUIPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ux-design/index" } }) {
      fields {
        firstDesignWorkRecommended {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
          }
        }
        secondDesignWorkRecommended {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
          }
        }
        thirdDesignWorkRecommended {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
          }
        }
        fourthDesignWorkRecommended {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
          }
        }
      }
      frontmatter {
        templateKey
        featuredBlog
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Design" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useInterval } from '../../../utils/hooks';

import './index.css';
import workDots from '../../../img/work-dots.svg';
import outerCircle from '../../../img/circle-graphic.svg';
import innerCircle from '../../../img/circle-graphic-inner-circle.svg';
import researchDesktopImage from '../../../img/ux-research-icon.svg';
import researchTabletImage from '../../../img/ux-research-icon-tablet.svg';
import researchMobileImage from '../../../img/ux-research-icon-mobile.svg';
import strategyDesktopImage from '../../../img/ux-strategy-and-ia-icon.svg';
import strategyTabletImage from '../../../img/ux-strategy-and-ia-icon-tablet.svg';
import strategyMobileImage from '../../../img/ux-strategy-and-ia-icon-mobile.svg';
import designDesktopImage from '../../../img/ui-design-icon.svg';
import designTabletImage from '../../../img/ui-design-icon-tablet.svg';
import designMobileImage from '../../../img/ui-design-icon-mobile.svg';
import usabilityDesktopImage from '../../../img/usability-testing-icon.svg';
import usabilityTabletImage from '../../../img/usability-testing-icon-tablet.svg';
import usabilityMobileImage from '../../../img/usability-testing-icon-mobile.svg';

const data = [
  {
    title: 'UX Research',
    body:
      'An effective user experience begins with research into your audience’s core motivations. We compile and sort through relevant user data to develop rich user stories that help us empathize with their life experiences. This may include focus groups, surveys, interviews, and other methods to identify relevant data.',
    desktopImage: researchDesktopImage,
    tabletImage: researchTabletImage,
    mobileImage: researchMobileImage,
    items: [
      'Discovery Workshops',
      'User Personas',
      'User Journey Mapping',
      'User Interviews',
      'Surveys'
    ]
  },
  {
    title: 'UX Strategy & Information Architecture',
    body:
      'After we arrive at a place of empathy, we develop a custom UX/UI strategy for your software. Defining strategic information architecture is an essential step in your initial design process. The informational hierarchy of your product establishes the framework for navigation across your product and can make or break the success of your user experience.',
    desktopImage: strategyDesktopImage,
    tabletImage: strategyTabletImage,
    mobileImage: strategyMobileImage,
    items: [
      'Card Sorting',
      'Treejack Testing',
      'Impact vs Effort',
      'Sprint Planning'
    ]
  },
  {
    title: 'UI Design',
    body:
      'With a firm understanding of your users, product requirements, and a logical design strategy, we can begin user interface design. User interface design is an exciting and iterative component of the overall software development process. Your UI should act as a natural extension of your existing brand while facilitating a logical and relevant UX.',
    desktopImage: designDesktopImage,
    tabletImage: designTabletImage,
    mobileImage: designMobileImage,
    items: [
      'Wireframes',
      'Interactive Prototypes',
      'High Fidelity Designs',
      'Style Guides'
    ]
  },
  {
    title: 'Usability Testing',
    body:
      'The final phase of the UX/UI design process is to test and iterate. We validate that the design decisions support the user experience via user testing. User testing allows us to verify designs before developers write a single line of code. Once thoroughly tested, designs are finalized and development can begin.',
    desktopImage: usabilityDesktopImage,
    tabletImage: usabilityTabletImage,
    mobileImage: usabilityMobileImage,
    items: [
      'Moderated Testing',
      'Unmoderated Testing',
      'Task Completion Testing',
      'A/B Testing',
      'Multivariate Testing'
    ]
  }
];
const defaultInterval = 12000;

export default () => {
  const [activeResearch, setActiveResearch] = useState(0);
  const [activeResearchTablet, setActiveResearchTablet] = useState(0);
  const [interval, setInterval] = useState(defaultInterval);
  const [reset, setReset] = useState(null);

  useEffect(() => {
    setReset(true);
    const timeout = setTimeout(() => {
      setReset(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, [activeResearch]);

  useEffect(() => {
    if (!interval) setInterval(defaultInterval);
  }, [interval]);

  useInterval(() => {
    activeResearch === 3
      ? setActiveResearch(0)
      : setActiveResearch(activeResearch + 1);
  }, interval);

  const handleOnClick = (index) => {
    setActiveResearch(index);
    setReset(false);
    setInterval(null);
  };

  return (
    <section className="uxui-research ">
      <div className="desktop root-padding">
        <img
          className="dots"
          src={workDots}
          alt="Dots"
        />
        <div className="left">
          <img
            className="outer-circle"
            src={outerCircle}
            alt="Outer circle"
          />
          <img
            className={`inner-circle ${'side-' + activeResearch}`}
            src={innerCircle}
            alt="Inner circle"
          />
          <img
            className="center-image"
            src={data[activeResearch].desktopImage}
            alt="Center"
          />
          <p
            onClick={() => handleOnClick(0)}
            className={`top-text ${activeResearch === 0 ? 'active' : ''}`}
          >
            UX Research
          </p>
          <p
            onClick={() => handleOnClick(1)}
            className={`right-text ${activeResearch === 1 ? 'active' : ''}`}
          >
            UX Strategy &<br /> Info Architecture
          </p>
          <p
            onClick={() => handleOnClick(2)}
            className={`bottom-text ${activeResearch === 2 ? 'active' : ''}`}
          >
            UI Design
          </p>
          <p
            onClick={() => handleOnClick(3)}
            className={`left-text ${activeResearch === 3 ? 'active' : ''}`}
          >
            Usability
            <br /> Testing
          </p>
        </div>
        <div className="right">
          <div className="progress-bar">
            <div className={reset ? 'change' : 'loading'}></div>
          </div>
          <p className="c-title">{data[activeResearch].title}</p>
          <p className="body">{data[activeResearch].body}</p>
          <div className="items">
            {data[activeResearch].items.map((item, index) => (
              <ul>
                <li key={index}>{item}</li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <div className="tablet">
        <div className="ui-tabs root-padding">
          {data.map((item, index) => (
            <p
              onClick={() => setActiveResearchTablet(index)}
              className={activeResearchTablet === index ? 'active' : ''}
              key={index}
            >
              {item.title}
            </p>
          ))}
        </div>
        <div className="bottom">
          <img
            src={data[activeResearchTablet].tabletImage}
            alt={data[activeResearchTablet].title}
          />
          <div className="right">
            <p className="body">{data[activeResearchTablet].body}</p>
            <div className="items">
                {data[activeResearchTablet].items.map((item, index) => (
                  <ul>
                    <li key={index}>{item}</li>
                  </ul>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile root-padding">
        {data.map((object, index) => (
          <div className="c-card" key={index}>
            <img src={object.mobileImage} alt={object.title} />
            <p className="c-title">{object.title}</p>
            <p className="body">{object.body}</p>
            <div className="items">
                {object.items.map((item, index) => (
                  <ul>
                    <li key={index + '-aux'}>{item}</li>
                  </ul>
                ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

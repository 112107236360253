import React from 'react';

import './index.css';
import orangeEllipse from '../../../img/orange-ellipse.svg';

export default ({ title, body }) => {
  return (
    <section className="uxui-red secondary-padding">
      <img src={orangeEllipse} alt="Ellipse" />
      <p className="c-title">{title}</p>
      <div className="body">{body}</div>
    </section>
  );
};
